import { FC } from 'react';
import IconProps from './IconProps';

const DatabaseIcon: FC<IconProps> = (props) => {
  const { onClick, className, accentColor = '#C5A76B', gradient } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  if (gradient) {
    return (
      <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.8286 21.2069C10.8286 21.7762 10.3437 22.2297 9.78162 22.1395C6.4134 21.5992 3.99792 19.7168 3.99792 17.421V7.01237C3.99792 4.27684 7.42628 2.1333 11.8044 2.1333C16.1826 2.1333 19.6109 4.27684 19.6109 7.01237V16.12C19.6109 16.6589 19.1741 17.0958 18.6351 17.0958C18.0962 17.0958 17.6593 16.6589 17.6593 16.12V15.8643C16.9815 16.2507 15.1372 17.0987 12.0053 17.0987C11.9431 17.0987 11.8831 17.0977 11.825 17.0959C11.8182 17.0958 11.8113 17.0958 11.8044 17.0958C9.44947 17.0958 7.36773 16.468 5.94955 15.4694V17.4276C5.94955 18.551 7.51222 19.7657 9.93841 20.1929C10.4386 20.2809 10.8286 20.6991 10.8286 21.2069ZM15.1248 14.5896C14.0602 14.9691 12.936 15.1571 11.8044 15.1441C8.35331 15.1441 5.94955 13.6024 5.94955 12.2167V10.2651C7.36773 11.2637 9.44947 11.8914 11.8044 11.8914C14.1594 11.8914 16.2411 11.2702 17.6593 10.2651V13.1717C16.7951 13.8278 15.9435 14.282 15.1248 14.5896ZM17.6593 7.01237C17.6593 5.62671 15.2556 4.08493 11.8044 4.08493C8.35331 4.08493 5.94955 5.62671 5.94955 7.01237C5.94955 8.39802 8.35331 9.93981 11.8044 9.93981C15.2556 9.93981 17.6593 8.39802 17.6593 7.01237Z"
          fill="url(#paint0_linear_4183_66133)"
        />
        <path
          d="M16.6963 19.0868C16.3213 18.7093 15.7106 18.7092 15.3356 19.0868C14.9643 19.4608 14.9643 20.0643 15.3356 20.4382L17.5332 22.6509C17.7123 22.8315 17.9554 22.9331 18.2089 22.9333C18.3371 22.9311 18.4635 22.903 18.5807 22.8506C18.6978 22.7983 18.8034 22.7227 18.891 22.6284L23.0229 18.1906C23.382 17.8049 23.3623 17.2017 22.9789 16.8403C22.5916 16.4753 21.981 16.4958 21.619 16.886L18.1866 20.5873L16.6963 19.0868Z"
          fill="url(#paint1_linear_4183_66133)"
        />
        <defs>
          <linearGradient id="paint0_linear_4183_66133" x1="3.99792" y1="8.24557" x2="20.6166" y2="13.7865" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint1_linear_4183_66133" x1="14.6646" y1="18.2405" x2="22.7057" y2="22.8237" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0309 21.2069C10.0309 21.7762 9.54599 22.2297 8.98389 22.1395C5.61568 21.5993 3.2002 19.7168 3.2002 17.4211V7.0124C3.2002 4.27687 6.62855 2.13333 11.0067 2.13333C15.3849 2.13333 18.8132 4.27687 18.8132 7.0124V16.12C18.8132 16.6589 18.3763 17.0958 17.8374 17.0958C17.2985 17.0958 16.8616 16.6589 16.8616 16.12V15.8643C16.1837 16.2507 14.3394 17.0987 11.2076 17.0987C11.1454 17.0987 11.0853 17.0978 11.0273 17.0959C11.0204 17.0959 11.0136 17.0958 11.0067 17.0958C8.65174 17.0958 6.57001 16.468 5.15182 15.4694V17.4276C5.15182 18.5511 6.71449 19.7657 9.14068 20.1929C9.64085 20.281 10.0309 20.6991 10.0309 21.2069ZM14.3271 14.5897C13.2625 14.9691 12.1383 15.1571 11.0067 15.1442C7.55558 15.1442 5.15182 13.6024 5.15182 12.2167V10.2651C6.57001 11.2637 8.65174 11.8915 11.0067 11.8915C13.3617 11.8915 15.4434 11.2702 16.8616 10.2651V13.1717C15.9974 13.8278 15.1457 14.2821 14.3271 14.5897ZM16.8616 7.0124C16.8616 5.62674 14.4578 4.08496 11.0067 4.08496C7.55558 4.08496 5.15182 5.62674 5.15182 7.0124C5.15182 8.39805 7.55558 9.93984 11.0067 9.93984C14.4578 9.93984 16.8616 8.39805 16.8616 7.0124Z"
        fill="currentColor"
      />
      <path
        d="M15.899 19.0869C15.524 18.7093 14.9133 18.7093 14.5383 19.0869C14.1669 19.4608 14.1669 20.0643 14.5383 20.4383L16.7358 22.6509C16.9149 22.8315 17.158 22.9331 17.4115 22.9333C17.5397 22.9312 17.6661 22.903 17.7833 22.8507C17.9005 22.7983 18.006 22.7227 18.0936 22.6285L22.2256 18.1906C22.5847 17.805 22.565 17.2018 22.1815 16.8403C21.7942 16.4753 21.1836 16.4958 20.8217 16.8861L17.3892 20.5873L15.899 19.0869Z"
        fill={accentColor}
      />
    </svg>
  );
};

export default DatabaseIcon;
